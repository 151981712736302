// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor, Quill } from 'vue-quill-editor'
import htmlEditButton from 'quill-html-edit-button'
import Editor from '@tinymce/tinymce-vue'

Quill.register('modules/htmlEditButton', htmlEditButton)

const EditorMixin = {
  components: {
    quillEditor,
    Editor,
  },
  data() {
    return {
      editorOptions: {
        modules: {
          htmlEditButton: {},
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],

            [{ header: 1 }, { header: 2 }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ direction: 'rtl' }],

            [{ size: ['small', false, 'large', 'huge'] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }],

            ['clean', 'link', 'image', 'video'],
          ],
        },
      },
      tinyMCEapi: 'zap7xizcd9dq4spvcvlypbocnobpxyuloon07hqnn7lswpja',
      tinyMCEoptions: {
        // menubar: false,
        plugins: 'anchor autolink charmap codesample emoticons image link lists media table visualblocks wordcount checklist mediaembed casechange formatpainter pageembed linkchecker permanentpen powerpaste advtable advcode editimage tableofcontents footnotes',
        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
        fontsize_formats: '10px 12px 14px 16px 18px 20px 22px 24px 26px 28px 30px 32px',

        automatic_uploads: true,
        file_picker_types: 'image',
        file_picker_callback: (cb, value, meta) => {
          if (meta.filetype === 'image') {
            const input = document.createElement('input')
            input.setAttribute('type', 'file')
            input.setAttribute('accept', 'image/*')
            input.onchange = () => {
              let names = ''
              const file = input.files[0]
              const reader = new FileReader()
              const data = new FormData()
              data.append('disk', 'file-manager')
              data.append('path', 'blog/')
              data.append('overwrite', 1)
              data.append('files[]', file)

              reader.onload = () => {
                this.$http.post(`${process.env.VUE_APP_API_URL}/file-manager/upload`, data, {
                  headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    accept: '*/*',
                  },
                })
                  .then(res => {
                    names = res.data.result.names
                    /* call the callback and populate the Title field with the file name */
                    cb(`${process.env.VUE_APP_APP_URL}/file-manager/${names[0]}`, { title: file.name })
                  })
              }
              reader.readAsDataURL(file)
            }
            input.click()
          }
        },
      },
    }
  },
}

export default EditorMixin
